.pulsing {
  /* Chrome, Safari, Opera */
  -webkit-animation: PULSE 1s infinite;

  /* Internet Explorer */
  -ms-animation: PULSE 1s infinite;

  /* Standard Syntax */
  animation: PULSE 1.25s infinite;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes PULSE {
  0% {
    color: black;
  }
  50% {
    color: rgba(255, 0, 0);
  }
  100% {
    color: black;
  }
}

/* Internet Explorer */
@-ms-keyframes PULSE {
  0% {
    color: black;
  }
  50% {
    color: rgba(255, 0, 0);
  }
  100% {
    color: black;
  }
}

/* Standard Syntax */
@keyframes PULSE {
  0% {
    color: black;
  }
  50% {
    color: rgba(255, 0, 0);
  }
  100% {
    color: black;
  }
}
