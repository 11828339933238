body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

div.checkbox {
  font-weight: normal;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 10px;
}

@font-face {
  font-family: "TitleFont";
  src: local("TitleFont"), url("./fonts/GothamBold.otf") format("opentype");
}

@font-face {
  font-family: "GeneralFont";
  src: local("GeneralFont"), url("./fonts/georgia.ttf") format("truetype");
}

* {
  font-family: "GeneralFont";
}
